<template>
  <div style="height: 100% !important">
    <v-stepper class="transparent-bg" v-model="e1" flat style="height: 100%">
      <v-stepper-content :step="1" class="text-center transparent-bg" style="height: 100%">
        <v-card-title class="justify-center" style="color: #fcc03c !important">Gérez votre cabinet</v-card-title>
        <v-card-subtitle>Pour commencer, veuillez choisir où vous diriger. </v-card-subtitle>
        <v-row class="text-center align-center d-inline-flex row-study mt-5 mb-5">
          <v-col
            :cols="!$vuetify.breakpoint.xs ? '12' : '6'"
            md="6"
            v-for="(item, index) in items"
            v-bind:key="item.name"
          >
            <v-card
              :id="`agency-manager-${item.id}`"
              :href="item.disable ? '#' : item.path"
              outlined
              width="200"
              height="200"
              elevation="3"
              rounded="100"
              @mouseover="setHovered(item.id, true)"
              @mouseleave="setHovered(item.id, false)"
              class="align-center d-flex rounded-xl"
            >
              <v-card-text class="text-center pa-5 mt-5">
                <i :class="[item.iconClass, item.hovered ? 'EoleYellow--text' : 'EoleBlue--text']"></i>
                <p class="mt-5">{{ item.content }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
export default {
  computed: {
    items() {
      return this.$store.state.authorizedRoutes
        .filter(route => route.id_category === 6)
        .map(route => {
          return {
            id: route.id,
            id_route: route.id_route,
            content: route.description,
            path: route.path,
            iconClass: route.icon_class,
            animationClass: route.animation_class,
            disable: route.disabled,
            hovered: false,
          }
        })
    },
  },
  data: () => ({
    e1: 1,
  }),
  methods: {
    setHovered(id, value) {
      const item = this.items.find(item => item.id === id)
      if (item) {
        item.hovered = value
        this.$forceUpdate() // force Vue to update the view
      }
    },
  },
}
</script>
<style>
.mullerFont {
  font-family: muller;
  font-size: 1.4rem;
  font-weight: 200;
}

.row-study {
  max-width: 450px;
}

.transparent-bg {
  background-color: transparent !important;
}

.v-badge__badge .v-icon {
  color: white !important;
}
</style>
